<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <div id="goods">
      <p class="buy_tips">名字是小孩的品牌，好的品牌事半功倍</p>
      <div v-for="(item, index) in goods_list " :key="index"   @click="select_goods" :data-value="index" :class="select_index === index?'one_goods_select':'one_goods' ">
        <p class="title">{{ item.days_str }}</p>
        <p class="price">￥ {{ item.price }}</p>
      </div>
      <button class=" wx_pay_color in_body in_body_pay_button" @click="creat_order_and_pay" >微信支付 {{ current_goods.price }}元</button>
    </div>
    <van-swipe  class="orders my-swipe" vertical="true" autoplay="2500" indicator-color="rgba(255, 255, 255, .5)" indicator-active-color="#fff" >
      <van-swipe-item  v-for="( item,index) in order_list" :key="index" >
        <div>
          <img :src="item.buyer.avatar" />
          <span class="gray">{{item.buyer.nickname}}</span>
          <span class="gray">购买了</span>
          <span class="warn">{{item.title}}</span>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div id="goods_detail" v-html="goods_desc_str"></div>

    <ul id="last_right_c" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import router from '@/router/index.js'

export default {
  name: 'goods',
  store,
  data () {
    return {
      loading: true,
      goods_list: [],
      order_list: [],
      select_index: 0,
      current_goods: { price: 200, title: '六个月' },
      wx_pay_url: '',
      order_id: '',
      info: { way: '', page: '', tag: '', is_ios: '' },
      goods_desc_str: ''
    }
  },
  mounted () {
    this.get_goods_list()
  },

  methods: {
    get_goods_list () {
      this.info.way = this.$route.query.way
      this.info.page = this.$route.query.page
      this.info.tag = this.$route.query.tag
      this.info.is_ios = Utils.is_ios()

      const data = { info: this.info }
      axios.post('/goods_list/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(res.data)
          this.goods_list = res.data.data.goods_list
          this.order_list = res.data.data.order_list
          for (let i = 0; i < this.goods_list.length; i++) {
            if (this.goods_list[i].select) {
              this.select_index = i
              this.current_goods = this.goods_list[i]
              break
            }
          }
          this.goods_desc_str = res.data.data.goods_desc_str
          this.loading = false
          Utils.alert_tip_list('goods_index', res.data.data.tip_list)
        })
    },
    select_goods (e) {
      this.select_index = parseInt(e.currentTarget.dataset.value)
      this.current_goods = this.goods_list[this.select_index]
    },
    creat_order_and_pay () {
      const data = { goods_id: this.current_goods.id }
      axios.post('/creat_order_and_pay_h5/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(res.data)
          router.push({ name: 'order_detail', query: { id: res.data.data.order_id, new: true } })
          // this.loading = false
          // this.show.ask = true
          // this.wx_pay_url = res.data.data.wx_pay_url
          // this.order_id = res.data.data.order_id
          // const a = document.createElement('a')
          // a.setAttribute('reffer', 'www.ssqm.cn')
          // a.setAttribute('href', this.wx_pay_url)
          // a.click()
        })
    },
    // go_to_order_detail: function (e) {
    //   this.show.ask = false
    //   router.push({ name: 'order_detail', query: { id: this.order_id } })
    // },
    // to_pay: function (e) {
    //   const a = document.createElement('a')
    //   a.setAttribute('reffer', 'www.ssqm.cn')
    //   a.setAttribute('href', this.wx_pay_url)
    //   a.click()
    // },
    on_close () {
      this.show = { ask: false }
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    }
  }
}
</script>

<style >

.orders{
  height: 2rem;
}
.orders div{
  height: 2rem;
  line-height:2rem;
  text-align: center;
  color: #888;
}
.orders div img{
  width: 0.8rem;
  height:  0.8rem;
  border-radius: 1rem;
  vertical-align: middle;
  margin-right: 0.1rem;
  line-height:  0.8rem;
}
.orders div span{
  vertical-align: middle;
  margin: 0rem 0.1rem;
  line-height:  1rem;
}
.orders div a{
  display: inline-block;
  vertical-align: middle;
  line-height:  1rem;
}
#goods{
  background-color: #fefefe;
  padding: 0.3rem 0rem;
}
#goods div{
  display: inline-block;
  margin: 0.2rem 0.2rem 0.1rem 0.2rem;
  width: 2.5rem;
  text-align: center;
  border-radius: 0.2rem;
}
#goods div p{
  font-size: 0.45rem;
  padding-left: 0.12rem;
  text-align: center;
  font-weight: bold;
  height: 0.6rem;
  line-height: 0.6rem;
  margin: 0.4rem auto;
}
.one_goods{
  color: #F9AD39;
  border:0.01rem dashed #F9AD39;
}
.one_goods_select{
  background-color: #F9AD39;
  color: #fefefe;
}
.buy_tips{
  margin: 0.1rem 0rem;
  padding-top: 0.6rem;
  display: block;
  text-align: center;
  font-size: 0.4rem;
  color: #ccc;
}

#goods_detail {
  background: #fefefe;
  padding: 1rem 0.5rem;
}
#goods_detail .blue_title,#goods_list .blue_title{
  text-align: center;
  color: #fefefe;
  width: 8rem;
  margin: 0rem auto .5rem auto;
  background: rgba(37,130,173,.3);
  font-size: .5rem;
  height: .9rem;
  line-height: .9rem;
  border-radius: .8rem;
}
#goods_detail .li{
  padding: 0.3rem 0rem;
  border-bottom: 0.01rem lightgray dashed;
  text-align: left;
}
#goods_detail .li .title2, #goods_detail .li .right{
  display: inline-block;
  vertical-align: middle;
}
#goods_detail .li .title2{
  width: 1.8rem;
  color: orange;
  margin-right: 0.1rem;
}
#goods_detail .li .right{
  width: 6.9rem;
}
#goods_detail .li .right p{
  margin: 0.2rem 0rem;
  display: block;
  font-size: 0.32rem;
}

#goods_detail .blue{
  color: #74BCE0;
  text-align: center;
  margin-top: 0.6rem;
}
.in_body_pay_button{
  margin-bottom: 1rem;
  font-size: 0.4rem;
}
</style>
